import { useState, Fragment, useEffect } from "react";
import "./App.css";
import left from "./chevron-left.svg";
import right from "./chevron-right.svg";

function App() {
  const [index, setIndex] = useState(0);
  const [showTitle, setShowTitle] = useState(true);
  const images2 = [
    {
      url: "https://images.tristanvanoosterom.nl/20211207_131638.jpg",
      orientation: "landscape",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211207_135650.jpg",
      orientation: "landscape",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211208_164136-edit.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211209_093833.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211209_130120.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211215_105912.jpg",
      orientation: "landscape",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211216_013142.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211219_155326-edit.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211221_142205.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211231_141108.jpg",
      orientation: "landscape",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20220101_172028.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20220106_113255.jpg",
      orientation: "landscape",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20220108_115529-edit2.jpg",
      orientation: "portrait",
    },
    // {
    //   url: "https://images.tristanvanoosterom.nl/20220109_123425.jpg",
    //   orientation: "portrait",
    // },
    {
      url: "https://images.tristanvanoosterom.nl/20220112_194055.jpg",
      orientation: "portrait",
    },
    // {
    //   url: "https://images.tristanvanoosterom.nl/20220130_191343.jpg",
    //   orientation: "portrait",
    // },
    {
      url: "https://images.tristanvanoosterom.nl/20220131_173917.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20220202_083655-edit.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/20211208_164136.jpg",
      orientation: "landscape",
    },
    // {
    //   url: "https://images.tristanvanoosterom.nl/20211207_131244.jpg",
    //   orientation: "landscape",
    // },
    {
      url: "https://images.tristanvanoosterom.nl/Tristan_Gezichtmozaiek.jpg",
      orientation: "portrait",
    },
    {
      url: "https://images.tristanvanoosterom.nl/Tristan_Gezichtmozaiek2.jpg",
      orientation: "portrait",
    },
  ];

  let isMobile = window.innerWidth < 640 || window.innerHeight < 640;
  let isDesktop = !isMobile;

  useEffect(() => {
    if (showTitle) {
      setShowTitle(index === 0);
    }
    //console.log("showTitle", showTitle);
  }, [index, showTitle]);

  useEffect(() => {
    const timer = setTimeout(() => {
      //console.log("10 sec");
      setShowTitle(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);
  //console.log("size:", images2.length);
  function prev() {
    let newIndex = index;
    newIndex--;
    if (newIndex < 0) {
      newIndex = images2.length - 1;
    }
    setIndex(newIndex);

    // console.log("prev", index);
  }

  function next() {
    let newIndex = index;
    newIndex++;
    if (newIndex > images2.length - 1) {
      newIndex = 0;
    }
    setIndex(newIndex);

    //console.log("next", index);
  }
  let backgroundSize;
  if (images2[index].orientation === "landscape") {
    backgroundSize = "cover";
  } else {
    backgroundSize = "auto 100%";
  }
  return (
    <Fragment>
      {isMobile && <MobileText showTitle={showTitle}></MobileText>}
      {isDesktop && <DesktopText showTitle={showTitle}></DesktopText>}
      <div
        style={{
          margin: "0em",
          backgroundColor: "black",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${images2[index].url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: backgroundSize,
            height: "100vh",
            width: "100vw",
          }}
        ></div>
        <button
          onClick={prev}
          style={{
            color: "white",
            position: "fixed",
            top: "0",
            bottom: "0",
            border: "none",
            background: "none",
            cursor: "pointer",
            zIndex: "9",
            width: "30vw",
          }}
        >
          <div style={{ position: "fixed" }}>
            <img
              heigth={"40px"}
              width={"40px"}
              src={left}
              alt="Vorige"
              style={{ cursor: "pointer" }}
            ></img>
          </div>
        </button>

        <button
          onClick={next}
          style={{
            color: "white",
            position: "fixed",
            top: "0",
            right: "0",
            bottom: "0",
            border: "none",
            background: "none",
            cursor: "pointer",
            zIndex: "9",
            width: "30vw",
          }}
        >
          <div style={{ position: "fixed", right: "0" }}>
            <img
              heigth={"40px"}
              width={"40px"}
              src={right}
              alt="Volgende"
              style={{ cursor: "pointer" }}
            ></img>
          </div>
        </button>
      </div>
    </Fragment>
  );
}
function DesktopText({ showTitle }) {
  return (
    <div
      className={`${showTitle ? "" : "hidden"}`}
      style={{
        position: "absolute",
        bottom: "0px",
        left: "50%",
        margin: "auto",
        marginLeft: "-200px",
        padding: "1em",
        textAlign: "center",
        width: "400px",
      }}
    >
      <h2
        style={{
          padding: "0.5em",
          margin: "1em",
          backgroundColor: "hsla(0, 0%, 0%, 0.4)",
          color: "white",
          borderRadius: "0.5em",
          textAlign: "center",
        }}
      >
        Tristan Nino van Oosterom
        <br />7 december 2021
      </h2>
    </div>
  );
}

function MobileText({ showTitle }) {
  return (
    <Fragment>
      <div
        className={`${showTitle ? "" : "hidden"}`}
        style={{
          position: "absolute",
          bottom: "0px",
          left: "50%",
          margin: "auto",
          marginLeft: "-90px",
          padding: ".5em",
          textAlign: "center",
          width: "180px",
        }}
      >
        <div
          style={{
            fontSize: ".6em",
            padding: "0.2em",
            margin: ".5em",
            backgroundColor: "hsla(0, 0%, 0%, 0.4)",
            color: "white",
            borderRadius: "0.5em",
            textAlign: "center",
          }}
        >
          Tristan Nino van Oosterom
          <br />7 december 2021
        </div>
      </div>
    </Fragment>
  );
}

export default App;
